import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaDownload, FaTrash } from "react-icons/fa";

const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const [selectedUserFiles, setSelectedUserFiles] = useState([]);
  const [showFilesPopup, setShowFilesPopup] = useState(false);
  const [totalStorageUsed, setTotalStorageUsed] = useState(0);

  // Fetch users when the component mounts
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("/api/admin/users", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Include token
          },
        });
        setUsers(response.data); // Fetch all users
      } catch (error) {
        console.error("Error fetching users", error);
      }
    };

    fetchUsers();
  }, []);

  // Handle file viewing for the selected user
  const handleViewFiles = async (userId) => {
    try {
      const response = await axios.get(`/api/admin/files/${userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const files = response.data;

      // Calculate total storage used
      const totalStorage = files.reduce((total, file) => total + file.size, 0);
      setTotalStorageUsed(totalStorage);

      setSelectedUserFiles(files);
      setShowFilesPopup(true);
    } catch (error) {
      console.error("Error fetching user files", error);
    }
  };

  // Handle file deletion
  const handleDeleteFile = async (fileId) => {
    try {
      await axios.delete(`/api/files/${fileId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      // Remove file from the list and recalculate storage
      setSelectedUserFiles((prevFiles) =>
        prevFiles.filter((file) => file._id !== fileId)
      );
      setTotalStorageUsed(
        (prevTotal) =>
          prevTotal - selectedUserFiles.find((file) => file._id === fileId).size
      );
      alert("File deleted successfully");
    } catch (error) {
      console.error("Error deleting file", error);
      alert("Error deleting file");
    }
  };

  // Handle tier update
  const handleTierChange = async (e, userId) => {
    const newTier = e.target.value;
    try {
      await axios.put(
        `/api/admin/users/${userId}`,
        { tier: newTier },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      alert(`User tier updated to ${newTier}`);
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user._id === userId ? { ...user, tier: newTier } : user
        )
      );
    } catch (error) {
      console.error("Error updating user tier", error);
      alert("Error updating user tier");
    }
  };

  // Format file size to display in MB or KB
  const formatFileSize = (size) => {
    return size > 1024 * 1024
      ? (size / (1024 * 1024)).toFixed(2) + " MB"
      : (size / 1024).toFixed(2) + " KB";
  };

  // Format the date for better readability
  const formatDate = (date) => {
    return new Date(date).toLocaleString();
  };

  const handleClosePopup = () => {
    setShowFilesPopup(false);
    setSelectedUserFiles([]);
    setTotalStorageUsed(0);
  };

  return (
    <div className="container">
      <h2>Admin - Manage Users</h2>
      <table className="file-table">
        <thead>
          <tr>
            <th>Email</th>
            <th>Number of Files</th>
            <th>Tier</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user._id}>
              <td>{user.email}</td>
              <td>{user.fileCount}</td>
              <td>
                <select
                  value={user.tier || "free"}
                  onChange={(e) => handleTierChange(e, user._id)}
                >
                  <option value="free">Free (50MB)</option>
                  <option value="tier2">Tier 2 (500MB)</option>
                  <option value="tier3">Tier 3 (1.5GB)</option>
                </select>
              </td>
              <td>
                <button onClick={() => handleViewFiles(user._id)}>
                  View Files
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showFilesPopup && (
        <div className="popup">
          <div className="popup-content">
            <h3>Files for Selected User</h3>

            {/* Total Storage Header */}
            <p>
              <strong>Total Storage Used:</strong>{" "}
              {formatFileSize(totalStorageUsed)}
            </p>

            {/* Table Headers */}
            <table className="file-table">
              <thead>
                <tr>
                  <th>File Name</th>
                  <th>Uploaded At</th>
                  <th>File Size</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {selectedUserFiles.map((file) => (
                  <tr key={file._id}>
                    <td>{file.filename}</td>
                    <td>{formatDate(file.uploadDate)}</td>
                    <td>{formatFileSize(file.size)}</td>
                    <td>
                      {/* Download Button */}
                      <a
                        href={file.downloadLink}
                        className="download-btn"
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        <FaDownload />
                      </a>
                      {/* Delete Button */}
                      <button
                        className="delete-btn"
                        onClick={() => handleDeleteFile(file._id)}
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Close Button */}
            <button onClick={handleClosePopup} style={{ marginTop: "20px" }}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPage;
