import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Navbar = ({ isLoggedIn, setIsLoggedIn }) => {
  const navigate = useNavigate();
  const adminEmail = "chiefnetch@gmail.com"; // Hardcoded admin email
  const userEmail = localStorage.getItem("userEmail"); // Retrieve the logged-in user's email

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userEmail");
    setIsLoggedIn(false); // Update login state to false
    alert("You have been logged out.");
    navigate("/login"); // Redirect to login
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <h1>FileHost</h1>
      </div>
      <ul className="navbar-links">
        {isLoggedIn ? (
          <>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <Link to="/upload">Upload Files</Link>
            </li>
            {userEmail === adminEmail && (
              <li>
                <Link to="/admin">Admin</Link>
              </li>
            )}
            <li>
              <button className="logout-btn" onClick={handleLogout}>
                Logout
              </button>
            </li>
          </>
        ) : (
          <>
            <li>
              <Link to="/login">Login</Link>
            </li>
            <li>
              <Link to="/register">Register</Link>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
