import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaInfoCircle, FaCopy } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Dashboard = ({ setIsLoggedIn }) => {
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axios.get("/api/files", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Include token
          },
        });
        setFiles(response.data); // Set files in state
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // If token is expired or unauthorized, clear token and redirect to login
          console.error("Token expired or unauthorized, logging out");
          localStorage.removeItem("token"); // Clear token
          setIsLoggedIn(false); // Set login state to false
          alert("You have been logged out due to token expiry."); // Show alert on token expiration
          navigate("/login"); // Redirect to login
        } else {
          console.error("Error fetching files", error); // Log other errors
        }
      }
    };

    fetchFiles();
  }, [navigate, setIsLoggedIn]);

  const handleShowPopup = (file) => {
    setSelectedFile(file);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setSelectedFile(null);
    setShowPopup(false);
  };

  const handleDeleteFile = async (fileId) => {
    try {
      await axios.delete(`/api/files/${fileId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Include token
        },
      });
      setFiles(files.filter((file) => file._id !== fileId)); // Remove file from state
      handleClosePopup(); // Close popup after deletion
      alert("File deleted successfully");
    } catch (error) {
      console.error("Error deleting file", error); // Log error
      alert("Error deleting file");
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(selectedFile.downloadLink);
    alert("Link copied to clipboard!");
  };

  const formatFileSize = (size) => {
    return size > 1024 * 1024
      ? (size / (1024 * 1024)).toFixed(2) + " MB"
      : (size / 1024).toFixed(2) + " KB";
  };

  return (
    <div className="container">
      <h2>Your Uploaded Files</h2>
      <table className="file-table">
        <thead>
          <tr>
            <th>File Name</th>
            <th>Date Uploaded</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {files
            .sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate))
            .map((file) => (
              <tr key={file._id}>
                <td>{file.filename}</td>
                <td>{new Date(file.uploadDate).toLocaleDateString()}</td>
                <td>
                  <FaInfoCircle
                    className="info-icon"
                    onClick={() => handleShowPopup(file)}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {showPopup && selectedFile && (
        <div className="popup">
          <div className="popup-content">
            <h3>{selectedFile.filename}</h3>
            <p>Size: {formatFileSize(selectedFile.size)}</p>
            <div className="link-container">
              <input
                type="text"
                value={selectedFile.downloadLink}
                readOnly
                className="download-link"
              />
              <button onClick={handleCopyLink} className="copy-button">
                <FaCopy />
              </button>
            </div>
            <button onClick={() => handleDeleteFile(selectedFile._id)}>
              Delete File
            </button>
            <button onClick={handleClosePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
