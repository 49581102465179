import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const FileUpload = ({ setIsLoggedIn }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [storageUsed, setStorageUsed] = useState(0);
  const [storageLimit, setStorageLimit] = useState(0);
  const [tier, setTier] = useState("");
  const [animatedUsed, setAnimatedUsed] = useState(0); // For text animation
  const [animatedProgress, setAnimatedProgress] = useState(0); // For bar animation
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStorageInfo = async () => {
      try {
        const response = await axios.get("/api/files", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const userResponse = await axios.get("/api/auth/user", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setTier(userResponse.data.tier);

        const totalUsed = response.data.reduce(
          (acc, file) => acc + file.size,
          0
        );

        let limit = 50 * 1024 * 1024; // Default to free tier (50 MB)
        if (userResponse.data.tier === "tier2") {
          limit = 500 * 1024 * 1024; // Tier 2 (500 MB)
        } else if (userResponse.data.tier === "tier3") {
          limit = 1.5 * 1024 * 1024 * 1024; // Tier 3 (1.5 GB)
        }

        setStorageUsed(totalUsed);
        setStorageLimit(limit);

        // Start animation after data is fetched
        animateStorageBar(totalUsed, limit);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.error("Token expired or unauthorized, logging out");
          localStorage.removeItem("token");
          setIsLoggedIn(false);
          alert("You have been logged out due to token expiry.");
          navigate("/login");
        }
      }
    };

    fetchStorageInfo();
  }, [navigate, setIsLoggedIn]);

  const animateStorageBar = (used, limit) => {
    let currentUsed = 0;
    let currentProgress = 0;
    const duration = 2000; // Animation duration in milliseconds
    const steps = 100; // Number of animation steps
    const intervalTime = duration / steps;

    const usedIncrement = used / steps;
    const progressIncrement = (used / limit) * (100 / steps);

    const interval = setInterval(() => {
      currentUsed += usedIncrement;
      currentProgress += progressIncrement;

      setAnimatedUsed((prev) => Math.min(prev + usedIncrement, used));
      setAnimatedProgress((prev) =>
        Math.min(prev + progressIncrement, (used / limit) * 100)
      );

      if (currentUsed >= used) {
        clearInterval(interval);
      }
    }, intervalTime);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");
    if (!token) {
      alert("You have been logged out due to token expiry.");
      navigate("/login");
      return;
    }

    if (!selectedFile) {
      alert("Please select a file.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      await axios.post("/api/files/upload", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      alert("File uploaded successfully");
      navigate("/dashboard");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Token expired during upload, logging out");
        localStorage.removeItem("token");
        setIsLoggedIn(false);
        alert("You have been logged out due to token expiry.");
        navigate("/login");
      } else {
        console.error("File upload failed", error);
        alert("File upload failed");
      }
    }
  };

  const formatSize = (size) => {
    return size > 1024 * 1024
      ? (size / (1024 * 1024)).toFixed(2) + " MB"
      : (size / 1024).toFixed(2) + " KB";
  };

  const getProgressColor = (percentage) => {
    if (percentage >= 90) {
      return "#8d2c1d";
    } else if (percentage >= 75) {
      return "#d2a92a";
    }
    return "#3c8d2f";
  };

  const storageUsedPercentage = (animatedUsed / storageLimit) * 100;

  return (
    <div className="container">
      <h2>Upload a File</h2>
      <div className="storage-info">
        <p>
          Storage Used: {formatSize(animatedUsed)} / {formatSize(storageLimit)}
        </p>
        <div className="progress-bar-container">
          <div
            className="progress-bar"
            style={{
              width: `${animatedProgress}%`,
              backgroundColor: getProgressColor(storageUsedPercentage),
              transition: "width 0.3s ease-out",
            }}
          ></div>
        </div>
        {tier !== "tier3" && (
          <div className="upgrade-container">
            <p>You can upgrade for more storage space.</p>
            <button onClick={() => navigate("/upgrade")}>Upgrade</button>
          </div>
        )}
      </div>
      <form onSubmit={handleUpload}>
        <input type="file" onChange={handleFileChange} />
        <button type="submit">Upload</button>
      </form>
    </div>
  );
};

export default FileUpload;
