import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./components/Login";
import Register from "./components/Register";
import Dashboard from "./components/Dashboard";
import FileUpload from "./components/FileUpload";
import Navbar from "./components/Navbar";
import AdminPage from "./components/AdminPage"; // Import the AdminPage

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("token"));
  const adminEmail = "chiefnetch@gmail.com"; // Hardcoded admin email
  const userEmail = localStorage.getItem("userEmail"); // Retrieve user email from localStorage

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, []);

  return (
    <Router>
      <div className="app-container">
        <Navbar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
        <div className="content">
          <Routes>
            {/* Redirect to /dashboard if logged in, otherwise show login */}
            <Route
              path="/"
              element={
                isLoggedIn ? (
                  <Navigate to="/dashboard" />
                ) : (
                  <Login setIsLoggedIn={setIsLoggedIn} />
                )
              }
            />
            <Route
              path="/login"
              element={<Login setIsLoggedIn={setIsLoggedIn} />}
            />
            <Route path="/register" element={<Register />} />

            {/* Protect /dashboard and /upload routes */}
            <Route
              path="/dashboard"
              element={
                isLoggedIn ? (
                  <Dashboard setIsLoggedIn={setIsLoggedIn} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/upload"
              element={
                isLoggedIn ? (
                  <FileUpload setIsLoggedIn={setIsLoggedIn} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />

            {/* Admin route for users with adminEmail */}
            <Route
              path="/admin"
              element={
                isLoggedIn && userEmail === adminEmail ? (
                  <AdminPage />
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />

            {/* Catch-all route for non-existent paths */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
